import { yupResolver } from '@hookform/resolvers/yup';
import { Settings } from '@mui/icons-material';
import { DialogContent, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import AppButton from 'app/shared-components/Buttons/AppButton';
import HookedTextfield from 'app/shared-components/Hooked/HookedTextField';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch } from 'app/store/index';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getApiError } from 'src/utils/getApiError';
import * as yup from 'yup';
import { useCreateTokenMutation, useFindCompanyTokenQuery } from './sync/store/integrate-hinova-api';

const formSchema = yup.object({
	token: yup.string().required('TOKEN_REQUIRED')
});

type FormType = yup.InferType<typeof formSchema>;

export default function HinovaConfigureDialog() {
	const { t } = useTranslation('integrationsPage');
	const dispatch = useAppDispatch();
	const [createToken, { isLoading }] = useCreateTokenMutation();

	const { token, isLoadingToken } = useFindCompanyTokenQuery('', {
		selectFromResult: ({ data, isLoading }) => ({
			token: data || null,
			isLoadingToken: isLoading
		})
	});

	const defaultValues = {
		token: token ? token.token : ''
	};
	const { control, handleSubmit, setValue } = useForm<FormType>({
		resolver: yupResolver(formSchema),
		defaultValues: defaultValues
	});

	const onSubmit = handleSubmit(async (data) => {
		try {
			await createToken(data).unwrap();
			dispatch(
				showMessage({
					message: t('CREATE_TOKEN_SUCCESS'),
					variant: 'success'
				})
			);
			dispatch(closeDialog());
		} catch (error) {
			const apiErr = getApiError(error, t);
			const defaultMsg = t('ERROR_WHILE_CREATING_TOKEN');
			dispatch(
				showMessage({
					message: apiErr || defaultMsg,
					variant: 'error'
				})
			);
		}
	});

	useEffect(() => {
		if (isLoadingToken) return;
		if (token) {
			setValue('token', token.token);
		}
	}, [token, setValue, isLoadingToken]);
	return (
		<DialogContent sx={{ overflowX: 'hidden', display: 'flex' }}>
			<Stack direction="column" spacing={3} className="w-[720px]" display="flex">
				<Stack gap={3}>
					<Stack direction="row" spacing={1} alignItems="center">
						<Settings color="secondary" />
						<Typography color="secondary" variant="h6" component="div">
							{t('CONFIGURE_INTEGRATION', { n: 'Hinova' })}
						</Typography>
					</Stack>
				</Stack>

				<Stack direction="row" spacing={1}>
					<HookedTextfield
						control={control}
						name="token"
						size="small"
						t={t}
						label={t('TOKEN')}
						required
						className="w-full"
					/>
				</Stack>
				<Box className="flex justify-end space-x-8">
					{' '}
					<AppButton variant="contained" color="error" onClick={() => dispatch(closeDialog())}>
						{t('CANCEL')}
					</AppButton>
					<AppButton
						variant="contained"
						className="w-[100px]"
						color="secondary"
						onClick={onSubmit}
						disabled={isLoading || isLoadingToken}
						loading={isLoading}
					>
						{t('SAVE')}
					</AppButton>
				</Box>
			</Stack>
		</DialogContent>
	);
}
