import { CircularProgress, IconButton, Stack, TextField } from '@mui/material';
import { EntityState, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import { ChangeEvent, memo, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { CloseOutlined, RefreshOutlined, SearchOutlined, TuneOutlined } from '@mui/icons-material';
import { TrackerStatus } from 'app/main/trackers/manage/types';
import { selectAllTrackers, useGetRealtimeQuery } from 'app/store/api/trackingSlice';
import { selectStateFilter } from 'app/store/map/actionBarSlice';
import { selectSelectedTrackerId, setFiltersOpen, setTrackersOpen } from 'app/store/map/mapSlice';
import { useDebounce, usePrevious } from 'src/app/services/hooks';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import userHasPermission from 'src/utils/useHasPermission';
import { POLLING_INTERVAL } from '../MapConfig';
import { getTrackerClass } from '../mapHelpers';
import { TTracker } from '../types';
import { itemContent } from './tracker-card-layouts';

const getFilteredList = (trackers: TTracker[], searchValue: string) => {
	if (!trackers.length) return [];
	const result = matchSorter(trackers, searchValue, {
		keys: ['did', 'vehicle.plate', 'vehicle.name', 'name', '_id']
	});
	return result;
};

const getActiveTrackers = (trackers: TTracker[]) =>
	trackers.filter((tracker) => {
		const status = _.get(tracker, 'status', false);
		return !status ? true : status === TrackerStatus.ACTIVE;
	});
const emptyArr = [];

const TrackerList = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation('mapPage');
	const [searchParams] = useSearchParams();
	const [searchValue, setSearchValue] = useState('');
	const stateFilter = useSelector(selectStateFilter);
	const debouncedSearch = useDebounce(searchValue, 500);
	const selectedTrackerId = useSelector(selectSelectedTrackerId);
	const prevSelectedTrackerId = usePrevious(selectedTrackerId);
	const listRef = useRef<VirtuosoHandle>(null);

	const isSystemAdmin = userHasPermission(SYSTEM_ADMIN_PERMISSION);

	const selectTrackersForList = useMemo(
		() =>
			createSelector(
				(res) => res.data,
				(_res, stateFilter) => stateFilter,
				(data: EntityState<TTracker>, stateFilter: unknown[]) => {
					if (!data) return emptyArr;
					const trackers = selectAllTrackers(data);
					const activeTrackers = getActiveTrackers(trackers);
					if (!stateFilter.length) return activeTrackers;
					return activeTrackers.filter((tracker) => {
						const trackerClass = getTrackerClass(tracker);
						return stateFilter.includes(trackerClass);
					});
				}
			),
		[]
	);
	const { trackers, refetch, isFetching } = useGetRealtimeQuery(searchParams.get('filterId') || '', {
		pollingInterval: POLLING_INTERVAL,
		selectFromResult: (res) => ({
			trackers: selectTrackersForList(res, stateFilter),
			...res
		})
	});

	const filteredList = useMemo(() => {
		if (!trackers) return emptyArr;
		if (!debouncedSearch) return trackers;
		const result = getFilteredList(trackers, debouncedSearch);
		return result;
	}, [debouncedSearch, trackers]);

	const closeDrawer = () => {
		dispatch(setTrackersOpen(false));
	};

	const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => setSearchValue(ev.target.value);
	const toggleAdvanceFilters = () => dispatch(setFiltersOpen(true));
	const handleRefreshRealtime = () => refetch();

	if (selectedTrackerId && prevSelectedTrackerId !== selectedTrackerId && filteredList.length) {
		const index = _.findIndex(filteredList, { _id: selectedTrackerId });
		// this timeout is unfortunately necessary
		setTimeout(
			() =>
				listRef?.current?.scrollToIndex({
					index,
					behavior: 'smooth'
				}),
			500
		);
	}

	return (
		<Stack className="flex flex-col w-full h-full overflow-y-auto items-center bg-transparent overflow-x-visible justify-center">
			<Stack
				direction="row"
				spacing={1}
				width="100%"
				justifyContent={['start', 'end']}
				alignItems={['center', 'center']}
				flexDirection="row"
				p={2}
				borderRadius={2}
				sx={{
					backgroundColor: (theme) => theme.palette.secondary.main
				}}
			>
				{isSystemAdmin && (
					<IconButton
						aria-label="Atualizar Lista de Rastreadores"
						size="small"
						onClick={handleRefreshRealtime}
						sx={{
							color: (theme) => theme.palette.background.paper
						}}
					>
						{isFetching ? (
							<CircularProgress sx={{ color: (theme) => theme.palette.background.paper }} size={24} />
						) : (
							<RefreshOutlined />
						)}
					</IconButton>
				)}
				<TextField
					InputProps={{
						startAdornment: (
							<IconButton
								size="small"
								aria-label="Abrir Filtros Avançados"
								onClick={toggleAdvanceFilters}
								color="secondary"
							>
								<TuneOutlined />
							</IconButton>
						),
						endAdornment: <SearchOutlined color="secondary" />
					}}
					sx={{
						'& .MuiOutlinedInput-root': {
							borderRadius: '28px',
							backgroundColor: (theme) => theme.palette.background.paper
						}
					}}
					size="small"
					fullWidth
					variant="outlined"
					placeholder={t('SEARCH')}
					value={searchValue}
					onChange={handleSearchChange}
				/>

				<IconButton
					sx={{
						color: (theme) => theme.palette.background.paper
					}}
					aria-label="Fechar Lista de Rastreadores"
					size="small"
					onClick={closeDrawer}
				>
					<CloseOutlined />
				</IconButton>
			</Stack>
			<Virtuoso
				style={{ width: '100%', scrollbarWidth: 'none' }}
				data={filteredList}
				itemContent={itemContent}
				ref={listRef}
				computeItemKey={(_i, t) => t.did}
			/>
		</Stack>
	);
};

const TrackerListMemo = memo(TrackerList);

export default TrackerListMemo;
