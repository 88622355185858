import { TrackerCommandStatus } from 'app/types/tracker-command-status.types';

const locale = {
	// Campos Obrigatórios

	COMPANY_REQUIRED: 'O campo da empresa é obrigatório',
	DID_MUST_BE_NUMBER: 'O campo DID deve ser um número',
	TRACKER_TYPE_REQUIRED: 'O campo de tipo de rastreador é obrigatório',
	DID_REQUIRED: 'O campo DID é obrigatório',
	NAME_REQUIRED: 'O campo de nome é obrigatório',
	WRONG_STATUS: 'O campo de status está errado',
	SELECT_COMPANY_FIRST: 'Selecione uma empresa primeiro',

	// Comuns

	NOTES: 'Notas',
	VEHICLE: 'Rastreado',
	NAME: 'Nome',
	TRACKER_TYPE: 'Tipo de rastreador',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	COMPANY: 'Empresa',
	DESC: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	CONFIRM: 'Confirmar',
	SIMCARD: 'Simcard',
	CAN_BE_BLOCKED: 'Pode ser bloqueado?',
	CAN_BE_BLOQUED_TOOLTIP: 'Isso indica se o rastreador pode ser configurado para bloquear o veículo',
	INFO_TRACKER: 'Informações do último pacote',
	EVENT_NAME: 'Nome do evento',
	SPEED: 'Velocidade',
	SERVER_TIME: 'Hora do servidor',
	GPS_TIME: 'Hora do gps',
	IGNITION_ON: 'Estado da ignição',
	VEHICLE_VOLTAGE: 'Tensão do veículo',
	BATTERY: 'Tensão da bateria',
	ODOMETER: 'Ôdometro',
	UNAVAILABLE: 'Indisponível',
	ON: 'Ligada',
	OFF: 'Desligada',
	LONGITUDE: 'Longitude',
	LATITUDE: 'Latitude',

	// Cabeçalho

	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpar filtros',
	TITLE: 'Rastreadores',
	DOWNLOAD_ALL: 'Baixar todos',
	ADD_USER: 'Adicionar Rastreador',
	NO_SIMCARDS: 'Nenhum simcard encontrado',
	NO_VEHICLES: 'Nenhum veículo encontrado',
	FILTER: 'Filtrar',
	// Barra lateral

	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Excluir',
	CANCEL: 'Cancelar',
	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	EDIT_TITLE: 'Editar rastreador',
	EDIT_INFO: 'Editar rastreadores',
	DELETE_INFO: 'Excluir rastreadores',
	DOWNLOAD_INFO: 'Baixar rastreadores selecionados',
	EDIT_MULTIPLE_INFO: 'Editar rastreadores selecionados',
	DELETE_MULTIPLE_INFO: 'Excluir rastreadores selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DELETE_DISABLED_MESSAGE: 'Não é possível executar essa ação',
	EDIT_DISABLED_MESSAGE: 'Não é possível editar rastreadores excluídos',
	DELETED_INFO_MESSAGE: 'Não é possível executar ações em massa em rastreadores excluídos.',
	// Tabela

	OF: 'de',
	ROWS_PER_PAGE: 'Itens por página',
	NO_TRACKERS_FOUND: 'Nenhum rastreador encontrado',

	// Modal

	EDIT_CONFIRMATION: 'Tem certeza de que deseja editar este rastreador?',
	EDIT_CONFIRMATION_MULTIPLE: 'Tem certeza de que deseja editar os rastreadores selecionados?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	DELETE_MODAL_TITLE: 'Excluir rastreador',
	DELETE_MODAL_DESCRIPTION: 'Tem certeza de que deseja excluir o rastreador',
	DELETE_MODAL_WARNING: 'Esta ação não pode ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Tem certeza de que deseja excluir {{n}} rastreadores',
	WARNING: 'Aviso',
	TRACKER_STATUS_WARNING:
		'Se o status do dispositivo for alterado para "Em estoque" e ele estiver associado a um veículo, o veículo será desassociado do rastreador.',
	TRACKER_STATUS_WARNING_2: 'Essa ação não pode ser desfeita. Tem certeza de que deseja fazer isso?',

	// Permissões

	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'Peça ao Administrador para adicionar as permissões ao seu perfil:',
	MINIMAL_END: 'Depois saia e entre no sistema novamente.',

	// Requisições

	CREATE_SUCCESS: 'Rastreador adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar rastreador',
	EDIT_SUCCESS: 'Rastreador atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar rastreador',
	DELETE_SUCCESS: 'Rastreador excluído com sucesso',
	DELETE_ERROR: 'Erro ao excluir rastreador',
	DOWNLOAD_ERROR: 'Erro ao baixar arquivo',
	EDIT_MULTIPLE_SUCCESS: 'Rastreadores atualizados com sucesso',
	EDIT_MULTIPLE_ERROR: 'Erro ao atualizar rastreadores',
	TRACKER_ALREADY_EXISTS_DELETED: 'Rastreador já existe e está excluído',
	// Tracker Status

	ACTIVE: 'Ativo',
	INACTIVE: 'Desativado',
	LOST: 'Perdido',
	DAMAGED: 'Danificado',
	STOCK: 'Em estoque',
	STATUS: 'Status do dispositivo',

	// Bulk
	SHOW_INVALID_ROWS: 'Mostrar linhas inválidas',
	CLEAR_TABLE: 'Limpar tabela',
	CREATING: 'Criando',
	DESCRIPTION_HEADER:
		'Aqui você pode criar rastreadores em massa, basta fazer o upload de um arquivo XLSX com os dados dos rastreadores, ou baixar o modelo e preencher com os dados dos rastreadores que deseja criar, você pode abrir o arquivo XLSX com o Excel ou outro editor de planilhas.',
	DOWNLOAD_TEMPLATE: 'Baixar modelo.',
	IMPORT: 'Importar',
	DROPZONE_TEXT: 'Arraste e solte o arquivo XLSX aqui ou clique para selecionar.',
	REMOVE_ROW: 'Remover linhas',
	ERROR: 'Erro',
	SUCCESS: 'Sucesso',
	VALIDS: 'Válidos',
	INVALIDS: 'Inválidos',
	MASSIVE_CREATE_SUCCESS: 'Rastreadores criados com sucesso, confira os detalhes no icone de resumo.',
	MASSIVE_CREATE_ERROR: 'Erro ao criar rastreadores',
	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar tabela',
	// Restore
	DELETED: 'Excluído',
	SHOW_DELETED: 'Itens excluídos',
	RESTORE_INFO: 'Restaurar rastreador',
	RESTORE_MODAL_TITLE: 'Restaurar rastreador',
	RESTORE_MODAL_DESCRIPTION: 'Tem certeza de que deseja restaurar o rastreador: {{name}}',
	RESTORE_SUCCESS: 'Rastreador restaurado com sucesso',
	RESTORE_ERROR: 'Erro ao restaurar rastreador',
	DELETED_AT: 'Excluído em',
	DELETED_BY: 'Excluído por',
	SATELLITES: 'Satélites',
	COMPANY_MUST_BE_NUMBER: 'O campo da empresa deve ser um número de CPF ou CNPJ, NÃO USE CARACTERES ESPECIAIS',
	PLATE_LENGTH: 'O campo do veículo deve ter 7 caracteres, representando a placa do veículo',

	SEND_COMMAND: 'Enviar comando',
	COMMAND_IDENTITY: 'Identificação do comando',
	COMMAND_IDENTITY_INFO:
		'É a identificação do comando que será enviado ao rastreador, usado para gerar o histórico de comandos enviados.',
	DID_INFO: 'Esse campo é preenchido automaticamente com o número do rastreador, não é necessário preencher.',
	COMMAND_IDENTITY_REQUIRED: 'O campo de identificação do comando é obrigatório',
	VALUE_REQUIRED: 'O campo é obrigatório',
	LAST_SENT_COMMAND: 'Último comando enviado:',
	[TrackerCommandStatus.Pending]: 'Pendente',
	[TrackerCommandStatus.Canceled]: 'Cancelado',
	[TrackerCommandStatus.Queued]: 'Agendado',
	[TrackerCommandStatus.Sent]: 'Enviado',
	[TrackerCommandStatus.Confirmed]: 'Confirmado',
	[TrackerCommandStatus.Scheduling]: 'Agendando',
	LAST_SENT_COMMAND_SUCCESS: 'Comando registrado com sucesso!',
	LAST_SENT_COMMAND_SUCCESS_INFO:
		'O envio do comando pode demorar alguns minutos, acompanhe o status de envio abaixo.',
	LAST_SENT_COMMAND_ERROR: 'Erro ao enviar comando',
	LAST_SENT_COMMAND_ERROR_INFO: 'Ocorreu um erro ao enviar o comando, verifique a disponibilidade do rastreador.',
	RESPONSE: 'Resposta do rastreador:',
	LAST_SENT_COMMANDS: 'Últimos comandos enviados.',
	LAST_SENT_COMMANDS_DESCRIPTION:
		'Aqui você pode ver os últimos comandos enviados para o rastreador, clique para ver detalhes.',
	SEND_COMMAND_TO: 'Enviar comando para',
	CLEAR_FILTERS: 'Limpar filtros',

	MASS_COMMAND_LABEL: 'Enviar comandos para selecionados ',
	MASS_COMMAND_LABEL_NO_TRACKERTYPE: 'Selecione um filtro de tipo de rastreador para enviar comandos',
	SEND_BULK_COMMAND: 'Enviar comando em massa',
	COMMANDS_SENT: 'Comandos enviados com sucesso',
	VIEW_COMMANDS: 'Ver comandos',
	DEFAULT_COMMANDS: 'Comandos padrão',
	USER_COMMANDS: 'Comandos do usuário',
	COMPANY_COMMANDS: 'Comandos da empresa',
	SEARCH_COMMANDS: 'Pesquisar comandos'
};

export default locale;
