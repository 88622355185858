import { FilterAltOff, SearchOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { alpha, SxProps } from '@mui/system';
import AppButton from 'app/shared-components/Buttons/AppButton';
import HookedTextfield from 'app/shared-components/Hooked/HookedTextField';
import { UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
interface FiltersProps {
	form: UseFormReturn<object>;
	searchInputProps: SxProps;
	handleApplyFilters: () => void;
	t: TFunction;
	isMobile: boolean;
	handleClearFilters: () => void;
}

const SearchFilterInput = ({
	form,
	searchInputProps,
	handleApplyFilters,
	handleClearFilters = () => {
		form.reset();
		handleApplyFilters();
	},
	t,
	isMobile
}: FiltersProps) => {
	return (
		<div className="flex flex-col sm:flex-row w-full sm:w-auto items-start">
			<HookedTextfield
				control={form.control}
				t={t}
				name="search"
				label={t('SEARCH')}
				placeholder={t('SEARCH')}
				size="small"
				sx={searchInputProps}
				noHelperText
				className="w-full sm:w-auto"
			/>
			{isMobile ? (
				<div className="flex flex-row gap-2">
					<AppButton
						className="w-full mt-16"
						variant="contained"
						color="secondary"
						size="large"
						onClick={handleApplyFilters}
						endIcon={<SearchOutlined fontSize="small" />}
					>
						{t('FILTER')}
					</AppButton>
					<Tooltip title={t('CLEAR_FILTERS')} arrow>
						<IconButton color="secondary" onClick={handleClearFilters} className="mt-16 w-56">
							<FilterAltOff />
						</IconButton>
					</Tooltip>
				</div>
			) : (
				<>
					<IconButton
						sx={(theme) => ({
							backgroundColor: theme.palette.secondary.main,
							color: theme.palette.getContrastText(theme.palette.secondary.main),
							':hover': { backgroundColor: alpha(theme.palette.secondary.main, 0.8) },
							marginLeft: '8px',
							width: '40px',
							height: '40px'
						})}
						onClick={handleApplyFilters}
					>
						<SearchOutlined fontSize="small" />
					</IconButton>
					<Tooltip title={t('CLEAR_FILTERS')} arrow>
						<IconButton color="secondary" onClick={handleClearFilters}>
							<FilterAltOff />
						</IconButton>
					</Tooltip>
				</>
			)}
		</div>
	);
};

export default SearchFilterInput;
