import HookedDatePicker from 'app/shared-components/Hooked/HookedDatePicker';
import { TFunction } from 'react-i18next';

const slotProps = {
  textField: {
    InputProps: {
      size: 'small',
      sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
    },
    InputLabelProps: {
      size: 'small',
      sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
    }
  } as const
};

interface StartFilterProps {
  t: TFunction;
  form: any;
  endDate?: any;
  name: string;
  noHelperText?: boolean;
}
export const StartFilter: React.FC<StartFilterProps> = ({ form, t, endDate, name, noHelperText }) => {

  return (
    <HookedDatePicker
      control={form.control}
      t={t}
      name={name}
      label={t('START_DATE')}
      sx={{ maxWidth: 250 }}
      className="w-full sm:w-auto"
      noHelperText={noHelperText}
      {...(noHelperText && slotProps)}
    />
  );
};
