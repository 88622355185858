import { TVehicle, TVehicleCarryLoad, VehicleSearchParams } from 'app/main/vehicle/types';
import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';
import { apiSlice } from './apiSlice';
import getStringQuery from './getStringQuery';
import { IApiPagination, TPaginatedQuery } from './types';

function getVehicleStringQuery(paginatedData: VehicleSearchParams) {
	const queryArgs = {
		company: paginatedData.company || null,
		tracker: paginatedData.tracker || null,
		type: paginatedData.type || null,
		queryId: paginatedData.queryId || null
	};

	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));
	return `&${query.toString()}`;
}

export interface IEditMultiplePayload {
	ids: string;
	year: number;
}
export interface TVehiclePaginatedQuery extends TPaginatedQuery {
	tracker?: string;
	company?: string;
	type?: string;
}

export type IPayload = {
	id?: string;
	name: string;
	plate: string;
	color: string;
	brand: string;
	vendor: string;
	model: string;
	year: number;
	carryLoad?: TVehicleCarryLoad;
	pictureUrl?: string;
	trackers: string[];
	fleets?: string[];
	company: string;
};

const baseUrl = '/vehicle';
const cacheKey = 'Vehicles';

export const vehiclesSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllVehicles: builder.query<IApiPagination<TVehicle>, TVehiclePaginatedQuery>({
			query: (paginatedData: TVehiclePaginatedQuery) => ({
				url: getStringQuery(paginatedData, baseUrl) + getVehicleStringQuery(paginatedData)
			}),
			providesTags: [cacheKey]
		}),
		getVehicle: builder.query<TVehicle, string>({
			query: (id) => ({
				url: `${baseUrl}/${id}`
			})
		}),
		updateVehicle: builder.mutation({
			query: (data) => ({
				url: `${baseUrl}/${data.id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),
		updateMultipleVehicles: builder.mutation<string, IEditMultiplePayload>({
			query: (data) => ({
				url: `${baseUrl}/list?ids=${data.ids}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),
		createVehicle: builder.mutation<string, IPayload>({
			query: (data) => ({
				url: baseUrl,
				method: 'POST',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),
		deleteVehicle: builder.mutation({
			query: (ids?: string) => ({
				url: `${baseUrl}/list?ids=${ids}`,
				method: 'DELETE'
			}),
			invalidatesTags: [cacheKey]
		}),
		uploadVehicleImage: builder.mutation({
			query: (formData: FormData) => ({
				url: '/upload/images/vehicle',
				method: 'POST',
				body: formData
			})
		}),
		getAllVehiclesByCompanies: builder.query<IApiPagination<TVehicle>, string[]>({
			query: (companies) => ({
				url: baseUrl,
				params: {
					...(companies.length && { company: companies }),
					limit: 0
				},
				method: 'GET'
			})
		})
	})
});

export const {
	useGetAllVehiclesQuery,
	useGetVehicleQuery,
	useUpdateVehicleMutation,
	useUpdateMultipleVehiclesMutation,
	useCreateVehicleMutation,
	useDeleteVehicleMutation,
	useUploadVehicleImageMutation,
	useGetAllVehiclesByCompaniesQuery
} = vehiclesSlice;
