import i18next from 'i18next';

const en = {
	COMPANY_ALREADY_EXISTS: 'This company already exists',
	COMPANY_NOT_FOUND: 'Company not found',
	COULD_NOT_FIND_ANY_HOST: 'Could not find any host',
	UNKNOWN_TRACKER_TYPE: 'Unknown tracker type',
	UNABLE_TO_FILTER_BY_USER_AND_RULER: 'Unable to filter by user and ruler',
	PANEL_COMPANY_AND_WIDGET_COMPANY_ARE_NOT_THE_SAME: 'Panel company and widget company are not the same',
	REQUESTED_PANEL_NOT_FOUND: 'Requested panel not found',
	REQUESTED_WIDGED_NOT_FOUND: 'Requested widget not found',
	USER_ALREADY_REGISTERED_AS_A_DRIVER: 'User already registered as a driver',
	DRIVER_NOT_FOUND: 'Driver not found',
	SIMCARD_NOT_FOUND: 'Simcard not found',
	TRACKER_NOT_FOUND: 'Tracker not found',
	NO_ACCESS_TO_ANY_VEHICLE: 'No access to any vehicle',
	VEHICLE_CARRYLOAD_DOES_NOT_MATCH_VEHICLE_TYPE: 'Vehicle carryload does not match vehicle type',
	GPS_TIME_REQUIRED: 'GPS time required',
	AT_LEAST_ONE_FILTER_REQUIRED: 'At least one filter required',
	LATITUDE_VALUE_OUT_OF_RANGE: 'Latitude value out of range',
	LONGITUDE_VALUE_OUT_OF_RANGE: 'Longitude value out of range',
	UNABLE_TO_LOCALE_A_CORRESPONDING_ADDRESS: 'Unable to locale a corresponding address',
	END_DATE_MUST_BE_GREATER_THAN_START_DATE: 'End date must be greater than start date',
	EITHER_TRACKER_OR_VEHICLE_DOES_NOT_HAVE_POSITIONS: 'Either tracker or vehicle does not have positions',
	USER_ALREADY_EXISTS: 'User already exists',
	BLOCK_COMMAND_NOT_FOUND: 'Block command not found for this tracker',
	UNBLOCK_COMMAND_NOT_FOUND: 'Unblock command not found for this tracker',
	TRACKER_ALREADY_EXISTS: 'Tracker already exists',
	TRACKER_ALREADY_EXISTS_IN_ANOTHER_COMPANY:
		'Tracker already exists in another company, please request migration to support.',
	SIMCARD_NOT_1NCE_BRAND: 'Simcard is not 1nce brand',
	SIMCARD_1NCE_BAD_REQUEST: 'Simcard 1nce bad request',
	SIMCARD_1NCE_SMS_QUOTA_DEPLETED: 'Simcard 1nce sms quota depleted',

	USER_BLOCKED_EXCEPTION: 'User blocked',
	ACCESS_TIME_EXCEPTION: 'Access not allowed at this time',
	NOT_UNDER_ALLOWED_DOMAIN: 'Failed to login, domain not allowed',
	USER_CANT_SELF_UPDATE: 'User can not edit his access times',
	ACCESS_PERIOD_ALREADY_EXISTS: 'Access period with this name already exists',
	ACCESS_PERIOD_NOT_FOUND: 'Access period not found'
};

const pt = {
	COMPANY_ALREADY_EXISTS: 'Esta empresa já existe',
	COMPANY_NOT_FOUND: 'Empresa não encontrada',
	COULD_NOT_FIND_ANY_HOST: 'Não foi possível encontrar nenhum host',
	UNKNOWN_TRACKER_TYPE: 'Tipo de rastreador desconhecido',
	UNABLE_TO_FILTER_BY_USER_AND_RULER: 'Não foi possível filtrar por usuário e regra',
	PANEL_COMPANY_AND_WIDGET_COMPANY_ARE_NOT_THE_SAME: 'A empresa do painel e a empresa do widget não são as mesmas',
	REQUESTED_PANEL_NOT_FOUND: 'Painel solicitado não encontrado',
	REQUESTED_WIDGED_NOT_FOUND: 'Widget solicitado não encontrado',
	USER_ALREADY_REGISTERED_AS_A_DRIVER: 'Usuário já cadastrado como motorista',
	DRIVER_NOT_FOUND: 'Motorista não encontrado',
	SIMCARD_NOT_FOUND: 'Simcard não encontrado',
	TRACKER_NOT_FOUND: 'Rastreador não encontrado',
	NO_ACCESS_TO_ANY_VEHICLE: 'Sem acesso a nenhum veículo',
	TRACKER_ALREADY_EXISTS: 'Rastreador com esse DID já existe',
	VEHICLE_CARRYLOAD_DOES_NOT_MATCH_VEHICLE_TYPE: 'O porte do veículo não corresponde ao tipo de veículo',
	GPS_TIME_REQUIRED: 'GPS Time obrigatório',
	AT_LEAST_ONE_FILTER_REQUIRED: 'Pelo menos um filtro é necessário',
	LATITUDE_VALUE_OUT_OF_RANGE: 'Valor de latitude fora do intervalo',
	LONGITUDE_VALUE_OUT_OF_RANGE: 'Valor de longitude fora do intervalo',
	UNABLE_TO_LOCALE_A_CORRESPONDING_ADDRESS: 'Não foi possível localizar um endereço correspondente',
	END_DATE_MUST_BE_GREATER_THAN_START_DATE: 'A data final deve ser maior que a data inicial',
	EITHER_TRACKER_OR_VEHICLE_DOES_NOT_HAVE_POSITIONS: 'O rastreador ou o veículo não possui posições',
	USER_ALREADY_EXISTS: 'Usuário com esse email já existe',
	BLOCK_COMMAND_NOT_FOUND: 'Comando de bloqueio não encontrado para este rastreador',
	UNBLOCK_COMMAND_NOT_FOUND: 'Comando de desbloqueio não encontrado para este rastreador',
	TRACKER_ALREADY_EXISTS_IN_ANOTHER_COMPANY:
		'Esse rastreador já existe em outra empresa, por favor solicite migração para o suporte.',
	SIMCARD_NOT_1NCE_BRAND: 'Simcard não é da operadora 1nce',
	SIMCARD_1NCE_BAD_REQUEST: 'Ocorreu um erro ao tentar realizar a operação',
	SIMCARD_1NCE_SMS_QUOTA_DEPLETED: 'A cota de SMS da operadora 1nce foi esgotada',

	USER_BLOCKED_EXCEPTION: 'Usuário bloqueado',
	ACCESS_TIME_EXCEPTION: 'Acesso não permitido neste horário',
	NOT_UNDER_ALLOWED_DOMAIN: 'Falha ao fazer login, domínio não permitido',
	USER_CANT_SELF_UPDATE: 'Usuário não pode editar seus horários de acesso',
	ACCESS_PERIOD_ALREADY_EXISTS: 'Período de acesso com esse nome já existe',
	ACCESS_PERIOD_NOT_FOUND: 'Período de acesso não encontrado'
};

const sp = {
	COMPANY_ALREADY_EXISTS: 'Esta empresa ya existe',
	COMPANY_NOT_FOUND: 'Empresa no encontrada',
	COULD_NOT_FIND_ANY_HOST: 'No se pudo encontrar ningún host',
	UNKNOWN_TRACKER_TYPE: 'Tipo de rastreador desconocido',
	UNABLE_TO_FILTER_BY_USER_AND_RULER: 'No se pudo filtrar por usuario y regla',
	PANEL_COMPANY_AND_WIDGET_COMPANY_ARE_NOT_THE_SAME: 'La empresa del panel y la empresa del widget no son las mismas',
	REQUESTED_PANEL_NOT_FOUND: 'Panel solicitado no encontrado',
	REQUESTED_WIDGED_NOT_FOUND: 'Widget solicitado no encontrado',
	USER_ALREADY_REGISTERED_AS_A_DRIVER: 'Usuario ya registrado como conductor',
	DRIVER_NOT_FOUND: 'Conductor no encontrado',
	SIMCARD_NOT_FOUND: 'Simcard no encontrado',
	TRACKER_NOT_FOUND: 'Rastreador no encontrado',
	NO_ACCESS_TO_ANY_VEHICLE: 'Sin acceso a ningún vehículo',
	TRACKER_ALREADY_EXISTS: 'Rastreador ya existe',
	VEHICLE_CARRYLOAD_DOES_NOT_MATCH_VEHICLE_TYPE: 'El porte del vehículo no coincide con el tipo de vehículo',
	GPS_TIME_REQUIRED: 'GPS Time obligatorio',
	AT_LEAST_ONE_FILTER_REQUIRED: 'Al menos un filtro es necesario',
	LATITUDE_VALUE_OUT_OF_RANGE: 'Valor de latitud fuera de rango',
	LONGITUDE_VALUE_OUT_OF_RANGE: 'Valor de longitud fuera de rango',
	UNABLE_TO_LOCALE_A_CORRESPONDING_ADDRESS: 'No se pudo localizar una dirección correspondiente',
	END_DATE_MUST_BE_GREATER_THAN_START_DATE: 'La fecha final debe ser mayor que la fecha inicial',
	EITHER_TRACKER_OR_VEHICLE_DOES_NOT_HAVE_POSITIONS: 'Either tracker or vehicle does not have positions',
	USER_ALREADY_EXISTS: 'Usuario con este correo electrónico ya existe',
	BLOCK_COMMAND_NOT_FOUND: 'Comando de bloqueo no encontrado para este rastreador',
	UNBLOCK_COMMAND_NOT_FOUND: 'Comando de desbloqueo no encontrado para este rastreador',
	TRACKER_ALREADY_EXISTS_IN_ANOTHER_COMPANY:
		'Este rastreador ya existe en otra empresa, solicite la migración al soporte.',
	SIMCARD_NOT_1NCE_BRAND: 'Simcard no es de la operadora 1nce',
	SIMCARD_1NCE_BAD_REQUEST: 'Ocurrió un error al intentar realizar la operación',
	SIMCARD_1NCE_SMS_QUOTA_DEPLETED: 'La cuota de SMS de la operadora 1nce se agotó',
	USER_BLOCKED_EXCEPTION: 'Usuario bloqueado',
	ACCESS_TIME_EXCEPTION: 'Acceso no permitido en este momento',
	NOT_UNDER_ALLOWED_DOMAIN: 'Error al iniciar sesión, dominio no permitido',
	USER_CANT_SELF_UPDATE: 'El usuario no puede editar sus horarios de acceso',
	ACCESS_PERIOD_ALREADY_EXISTS: 'El período de acceso con este nombre ya existe',
	ACCESS_PERIOD_NOT_FOUND: 'Período de acceso no encontrado'
};

const injectExceptionErrorsTranslation = () => {
	i18next.addResourceBundle('en', 'exceptionErrors', en);
	i18next.addResourceBundle('pt', 'exceptionErrors', pt);
	i18next.addResourceBundle('sp', 'exceptionErrors', sp);
};

export { injectExceptionErrorsTranslation };
