import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import { CarRepair, Category, CheckCircle, Commute, DirectionsCar, Sync, Verified } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import AppButton from 'app/shared-components/Buttons/AppButton';
import HookedSelect from 'app/shared-components/Hooked/HookedSelect';

import _ from 'lodash';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCreateVehicleMutation } from 'app/store/api/vehiclesSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch, useAppSelector } from 'app/store/index';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { getApiError } from 'src/utils/getApiError';
import { Vehicle } from '../../types';
import { selectFindedAssociate, setFindedAssociate } from '../store/integrate-hinova';
import IconBox from './IconBox';

interface VehicleRowProps {
	veiculo: Vehicle;
	t: TFunction;

	vehiclesTypes: { _id: string; name: string; icon: string }[];
	isVehiclesTypesLoading: boolean;
}
const formSchema = yup.object({
	vehicleType: yup
		.object({
			_id: yup.string(),
			name: yup.string()
		})
		.required('PROFILE_REQUIRED')
});

type FormType = yup.InferType<typeof formSchema>;

export const parseDataForAPi = (data: FormType & Vehicle & { company: string }) => {
	return {
		name: `${data.placa} - ${data.descricao_modelo}`,
		plate: data.placa,
		model: data.descricao_modelo,
		type: data.vehicleType?._id,
		chassis: data.chassi,
		color: null,
		brand: null,
		year: null,
		vendor: null,
		company: data.company,
		trackers: null
	};
};
const VehicleRow = ({ veiculo, t, vehiclesTypes, isVehiclesTypesLoading }: VehicleRowProps) => {
	const associate = useAppSelector(selectFindedAssociate);
	const [currentVehicle, setCurrentVehicle] = useState<Vehicle>(veiculo);
	const dispatch = useAppDispatch();

	const { control, setValue, handleSubmit } = useForm<FormType>({
		resolver: yupResolver(formSchema),
		defaultValues: {
			vehicleType: null
		}
	});

	useEffect(() => {
		if (isVehiclesTypesLoading) return;
		setCurrentVehicle(associate.veiculos.find((v) => v.placa === veiculo.placa));
		if (currentVehicle?.synced) {
			setValue(
				'vehicleType',
				vehiclesTypes.find((type) => type._id === currentVehicle?.type)
			);
			return;
		}
		setValue('vehicleType', vehiclesTypes[3]);
	}, [associate, vehiclesTypes, setValue, isVehiclesTypesLoading, veiculo, currentVehicle]);

	const [createVehicle, { isLoading }] = useCreateVehicleMutation();

	const onSubmit = handleSubmit(async (data) => {
		try {
			const parsed = parseDataForAPi({ ...currentVehicle, ...data, company: associate.company });
			await createVehicle(parsed);
			dispatch(
				showMessage({
					message: t('VEHICLE_SYNCED'),
					variant: 'success'
				})
			);
			dispatch(
				setFindedAssociate({
					...associate,
					veiculos: associate.veiculos.map((v) => {
						if (v.placa === currentVehicle.placa) {
							return { ...v, synced: true, type: parsed.type };
						}
						return v;
					})
				})
			);
		} catch (error) {
			const apiErr = getApiError(error, t);
			const defaultMsg = t('ERROR_WHILE_SAVING');
			dispatch(
				showMessage({
					message: apiErr || defaultMsg,
					variant: 'error'
				})
			);
		}
	});

	return (
		<Stack
			direction="row"
			sx={{
				width: '100%',
				background: (theme) => theme.palette.background.paper,
				paddingX: 2,
				borderRadius: 3
			}}
			spacing={1}
			alignItems="center"
			justifyContent="center"
			key={currentVehicle.placa}
		>
			<Stack direction="row" className="w-full flex gap-10 py-20 items-center">
				<IconBox icon={<DirectionsCar fontSize="medium" color="secondary" />} />
				<Typography sx={{ fontSize: '15px' }}>{`${currentVehicle?.placa}`}</Typography>
			</Stack>

			<Stack direction="row" className="w-full flex gap-10 py-20 items-center">
				<IconBox icon={<CarRepair fontSize="medium" color="secondary" />} />
				<Typography sx={{ fontSize: '15px' }}>{`${currentVehicle?.chassi}`}</Typography>
			</Stack>

			<Stack direction="row" className="w-full flex gap-10 py-20 items-center">
				<IconBox icon={<Commute fontSize="medium" color="secondary" />} />
				<Typography sx={{ fontSize: '15px' }}>{`${currentVehicle?.descricao_modelo}`}</Typography>
			</Stack>

			<Stack direction="row" className="w-full flex gap-10 py-20 items-center">
				<IconBox icon={<Verified fontSize="medium" color="secondary" />} />
				<Typography sx={{ fontSize: '15px' }}>{`${currentVehicle?.situacao}`}</Typography>
			</Stack>
			<Stack direction="row" className="w-[1000px]  gap-10 pr-20 items-center">
				<IconBox icon={<Category fontSize="medium" color="secondary" />} />
				<HookedSelect
					optionLabel="name"
					label={t('TYPE')}
					options={vehiclesTypes}
					loading={isVehiclesTypesLoading}
					fullWidth
					required
					size="small"
					control={control}
					noHelperText
					name="vehicleType"
					t={t}
					isDisabled={currentVehicle?.synced}
					renderOption={(props, option) => (
						<ListItem {...props}>
							<ListItemIcon>
								<FuseSvgIcon>{option?.icon?.replace('mat_outline:', 'material-outline:')}</FuseSvgIcon>
							</ListItemIcon>
							<ListItemText primary={_.capitalize(option?.name)} />
						</ListItem>
					)}
				/>
			</Stack>

			<AppButton
				variant="contained"
				color={currentVehicle?.synced ? 'success' : 'secondary'}
				size="large"
				disabled={!associate?.synced}
				onClick={() => {
					!currentVehicle?.synced && onSubmit();
				}}
				endIcon={
					isLoading ? <Sync className="animate-spin" /> : currentVehicle?.synced ? <CheckCircle /> : <Sync />
				}
				sx={{
					minWidth: '200px'
				}}
			>
				{currentVehicle?.synced ? t('SYNCED') : t('SYNC')}
			</AppButton>
		</Stack>
	);
};

export default VehicleRow;
