import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import _ from 'lodash';
import { UseControllerProps, useController } from 'react-hook-form';
import { TFunction } from 'react-i18next';

type TFormAndDatePicker<F> = UseControllerProps<F> &
	DateTimePickerProps<Dayjs | null> & { t: TFunction; required?: boolean; noHelperText?: boolean };

export default function HookedDateTimePicker<F>({
	control,
	name,
	label,
	t,
	format = 'DD/MM/YYYY - HH:mm',
	required = false,
	disableFuture = false,
	slotProps,
	noHelperText = false,
	...props
}: TFormAndDatePicker<F>) {
	const {
		field,
		formState: { errors }
	} = useController({ name, control });

	const handleChange = (date: Dayjs | null) => {
		if (date !== null && date.isValid()) field?.onChange(date);
		else field?.onChange(null);
	};

	const error = _.has(errors, name) ? t(_.get(errors, [name, 'message'])) : undefined;

	return (
		<DateTimePicker
			label={label}
			value={field?.value}
			onChange={handleChange}
			slotProps={{
				textField: {
					required,
					onBlur: field?.onBlur,
					helperText: noHelperText ? undefined : error,
					error: _.has(errors, name),
					size: 'small',
					fullWidth: true,
					name,
					...(slotProps?.textField || {})
				}
			}}
			ref={field?.ref}
			format={format}
			disableFuture={disableFuture}
			{...props}
		/>
	);
}
