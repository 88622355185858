import HookedDateTimePicker from 'app/shared-components/Hooked/HookedDateTimePicker';
import { TFunction } from 'react-i18next';

const slotProps = {
	textField: {
		InputProps: {
			size: 'small',
			sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
		},
		InputLabelProps: {
			size: 'small',
			sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
		}
	} as const
};
interface EndTimeFilterProps {
	t: TFunction;
	form: any;
	startDate?: any;
	name: string;
}
export const EndTimeFilter: React.FC<EndTimeFilterProps> = ({ t, form, startDate, name }) => {
	return (
		<HookedDateTimePicker
			control={form.control}
			t={t}
			name={name}
			label={t('END_DATE')}
			sx={{ minWidth: 250 }}
			slotProps={slotProps}
			shouldDisableDate={(date) => date.isBefore(startDate)}
			className="w-full sm:w-auto"
		/>
	);
};
