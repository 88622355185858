import HookedDatePicker from 'app/shared-components/Hooked/HookedDatePicker';
import { TFunction } from 'react-i18next';

const slotProps = {
	textField: {
		InputProps: {
			size: 'small',
			sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
		},
		InputLabelProps: {
			size: 'small',
			sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
		}
	} as const
};
interface EndFilterProps {
	t: TFunction;
	form: any;
	startDate?: any;
	name: string;
	noHelperText?: boolean;
}
export const EndFilter: React.FC<EndFilterProps> = ({ t, form, startDate, name, noHelperText }) => {
	return (
		<HookedDatePicker
			control={form.control}
			t={t}
			name={name}
			label={t('END_DATE')}
			sx={{ maxWidth: 250 }}
			className="w-full sm:w-auto"
			noHelperText={noHelperText}
			{...(noHelperText && slotProps)}
		/>
	);
};
