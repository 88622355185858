import { AutorenewRounded, CheckCircleOutlineOutlined, Delete, DoDisturbOnRounded } from '@mui/icons-material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import AppButton from 'app/shared-components/Buttons/AppButton';
import getTableTheme from 'app/shared-components/dataTableTheme';
import { useCreateVehicleMutation } from 'app/store/api/vehiclesSlice';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useAppSelector } from 'app/store/index';
import _ from 'lodash';
import { useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Vehicle } from '../../types';
import { selectFindedAssociate, setFindedAssociate } from '../store/integrate-hinova';
import { parseDataForAPi } from './VehicleRow';

function StatusIcon({ status, className }: { status: string; className?: string }) {
	const icon = {
		success: <CheckCircleOutlineOutlined className={className} color="success" />,
		error: <WarningAmberOutlinedIcon className={className} color="error" />,
		loading: <AutorenewRounded className={'animate-spin ' + className} />,
		pending: <DoDisturbOnRounded className={className} color="warning" />
	};
	return icon[status];
}
type VehicleFeedback = {
	vehicle: Vehicle;
	status?: string;
	message?: string;
};
export default function SyncAllModal({ vehicleTypes }) {
	const associate = useAppSelector(selectFindedAssociate);

	const vehicles = _.filter(associate?.veiculos, (v) => !v.synced);

	const initVehiclesFeedback = _.map(vehicles, (v) => ({
		vehicle: v,
		status: 'pending',
		message: 'pending'
	}));
	const [vehiclesFeedback, setVehiclesFeedback] = useState(initVehiclesFeedback);
	const [createVehicle] = useCreateVehicleMutation();
	const [progress, setProgress] = useState(0);

	const [isCreating, setIsCreating] = useState(false);

	const dispatch = useDispatch();
	const { t } = useTranslation('integrationsPage');
	const containerRef = useRef();

	const onFinish = () => {
		dispatch(closeDialog());
		dispatch(
			setFindedAssociate({
				...associate,
				veiculos: associate.veiculos.map((v) => {
					const feedback = vehiclesFeedback.find((vf) => vf.vehicle.placa === v.placa);
					return feedback ? { ...v, synced: feedback.vehicle.synced, type: feedback.vehicle.type } : v;
				})
			})
		);
	};
	const onRemove = (vehicle: Vehicle) => {
		setVehiclesFeedback((prev) => prev.filter((vf) => vf.vehicle.placa !== vehicle.placa));
	};

	const syncVehicles = async () => {
		setIsCreating(true);

		vehiclesFeedback.map(async (vehicleFeedback, index) => {
			setVehiclesFeedback((prev) => {
				prev[index] = { ...prev[index], status: 'loading', message: 'Loading' };
				return [...prev];
			});

			try {
				const parsed = parseDataForAPi({
					...vehicleFeedback.vehicle,
					vehicleType: vehicleTypes[3],
					company: associate.company
				});

				await createVehicle(parsed).unwrap();

				setVehiclesFeedback((prev) => {
					prev[index] = {
						...prev[index],
						vehicle: { ...prev[index].vehicle, synced: true, type: parsed.type },
						status: 'success',
						message: 'Sucesso'
					};
					return [...prev];
				});
			} catch (error) {
				setVehiclesFeedback((prev) => {
					prev[index] = { ...prev[index], status: 'error', message: 'Erro' };
					return [...prev];
				});
			}

			setProgress(((index + 1) / vehiclesFeedback.length) * 100);
		});
	};

	return (
		<Stack p={3} sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ position: 'relative', display: 'inline-flex' }}>
				<CircularProgress size={100} variant="determinate" value={progress} color="secondary" />
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Typography variant="caption" fontSize={20} component="div" color="text.secondary">
						{progress.toFixed(2)}%
					</Typography>
				</Box>
			</Box>
			<Box ref={containerRef} sx={{ width: '520px', height: '100%' }} paddingBottom={2}>
				<DataTable
					columns={[
						{
							name: t('REMOVE'),
							cell: (row: VehicleFeedback) => (
								<IconButton onClick={() => onRemove(row.vehicle)}>
									<Delete />
								</IconButton>
							),
							width: '20%'
						},

						{
							name: t('VEHICLES'),
							cell: (row: VehicleFeedback) => row.vehicle.placa,
							width: '200px'
						},
						{
							name: t('STATUS'),
							cell: (row: VehicleFeedback) => (
								<StatusIcon className="flex justify-center" status={row.status} />
							)
						}
					]}
					customStyles={getTableTheme()}
					responsive
					fixedHeader
					pointerOnHover
					highlightOnHover
					data={vehiclesFeedback}
					fixedHeaderScrollHeight="300px"
					noDataComponent={
						<Typography
							sx={{
								fontSize: ['16px', '18px'],
								fontWeight: 600,
								color: (theme) => theme.palette.secondary.main,
								textAlign: 'center'
							}}
						>
							{t('NO_VEHICLE_TO_SYNC')}
						</Typography>
					}
					sortServer
				/>
			</Box>
			<Stack direction="row" spacing={2}>
				<AppButton variant="contained" color="error" onClick={onFinish}>
					{t('CANCEL')}
				</AppButton>
				{!isCreating ? (
					<AppButton
						variant="contained"
						color="secondary"
						onClick={syncVehicles}
						disabled={!vehiclesFeedback.length}
					>
						{t('START')}
					</AppButton>
				) : (
					<AppButton variant="contained" color="secondary" onClick={onFinish}>
						{t('CONCLUDE')}
					</AppButton>
				)}
			</Stack>
		</Stack>
	);
}
